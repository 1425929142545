/**
 * NavigationHandler
 *
 * @version 2.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 *
 * @typedef {import('../antenne-frontend').NavigationEvent} NavigationEvent
 */

'use strict';

import ClassLogger from 'ClassLogger';
import nprogress from 'nprogress';

class NavigationError extends Error {
    constructor (message) {
        super(message);
        this.name = 'NavigationError';
    }
}

class NavigationHandler {
    getClassName () { return 'NavigationHandler'; }

    constructor (commonMethods, tracking, eventEmitter) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.tracking = tracking;
        this.eventEmitter = eventEmitter;

        /** @private */
        this._eventHandlers = {};
        this.parser = new DOMParser();
        this.abortController = new AbortController();
        this.sectionAttributeName = 'navhandlersection';
        this.readyAlreadyFired = false;

        nprogress.configure({
            minimum: 0.1,
            trickleSpeed: 1000,
            easing: 'ease',
            speed: 500,
            showSpinner: false,
        });

        this.on('*', (e) => {
            this.logger.log('==> ' + e.type, e.detail);
        });

        this.on('before-fetch', () => {
            if (window.antenne && window.antenne.offcanvas) {
                window.antenne.offcanvas.close();
            }

            this.tracking.track('fix:view:unload', {
                url: window.location.href,
            });
        });

        this.on('render', (event) => {
            this.tracking.track('fix:view', {
                url: event.detail.url,
                type: 'web',
                took: event.detail.took,
            });
            this.tracking.sendIdentifiers();
        });

        this.on('ready', (event) => {
            this.tracking.track('fix:view', {
                url: window.location.toString(),
                type: 'web',
            });
            this.tracking.sendIdentifiers();
        });

        // exposing the API must be done before triggering 'init'!
        window.navigationHandler = {
            isReady: this.readyAlreadyFired,
            on: (...args) => this.on(...args),
        };
        this.triggerEvent('init');

        this.commonMethods.isDomReady().then(() => {
            this.addClickListener();
            this.addInitialPushState();

            this.triggerEvent('ready', { url: window.location.href });
        });

        window.addEventListener('popstate', async (e) => {
            this.logger.log('popstate event', { state: e.state });
            if (e.state !== null && e.state.url) {
                this.abortPreviousNavigation();
                // const newDocument = this.parser.parseFromString(e.state.html, 'text/html');
                // await this.renderNewDocument(e.state.url, newDocument);

                // this.triggerEvent('render', {
                //     url: e.state.url,
                //     newDocument,
                //     took: 0,
                //     started: Date.now(),
                // });
                this.navigateTo(
                    e.state.url,
                    e.state.method || 'GET',
                    null,
                    e.state.scrollElementSelector || null,
                    true,
                );
            }
        });
    }

    /**
     * Create an initial history entry for the first rendered page.
     *
     * @see https://stackoverflow.com/a/11844412
     */
    addInitialPushState () {
        // this.pushState(window.location.href, 'GET');
        history.replaceState(
            {
                url: window.location.href,
                method: 'GET',
                scrollElementSelector: null,
            },
            '',
            window.location.href,
        );
    }

    pushState (url, method = 'GET', scrollElementSelector = null) {
        // html = html + ('#'.repeat(639990));
        // this.logger.log('HTML length is: ' + html.length, { url });
        // if (html.length > 640000) {
        //     this.logger.warn('HTML charcter size for pushState is > 640k. This might not work!', {
        //         url,
        //         length: html.length,
        //     });
        // }
        const state = {
            url,
            method,
            scrollElementSelector,
        };
        if (url === window.history.state.url && method === window.history.state.method) {
            this.logger.log('skipping pushState because URL and method are matching', state);
            return;
        }
        this.logger.log('pushing history state', state);
        try {
            history.pushState(
                state,
                '',
                url,
            );
        } catch (error) {
            this.logger.error('Failed to call pushState', {
                // length: html.length,
                error,
            });
        }
    }

    addClickListener () {
        window.addEventListener('click', (e) => {
            let targetNode = e.target;
            // Set this to true, to enable debugging on clicks
            const debugLog = false;
            if (debugLog) this.logger.log('Handling click', targetNode);

            if (!targetNode.href || targetNode.tagName.toLowerCase() !== 'a') {
                if (debugLog) this.logger.log('target node has no href attribute, looking for parents');
                // target has no href attribute, check if there is parent node with href
                // this might be the case, when e.g. a `<a>` has a `<span>` child that is clicked
                const parent = targetNode.parentNode;
                if (!parent) {
                    if (debugLog) this.logger.log('Ignoring link because of no href property');
                    return;
                }
                targetNode = parent.closest('[href]');
                if (!targetNode) {
                    if (debugLog) this.logger.log('Ignoring link because of no href property');
                    return;
                }
            }

            if (
                !targetNode.href ||
                targetNode.href === '' ||
                targetNode.href === '#' ||
                targetNode.getAttribute('href').startsWith('#') ||
                typeof targetNode.href !== 'string'
            ) {
                if (debugLog) this.logger.log('Ignoring link because of empty href');
                return;
            }

            if (targetNode.dataset.navhandlerlink === 'ignore-local') {
                if (debugLog) this.logger.log('Ignoring link because of data-navhandlerlink="ignore-local"');
                return;
            }

            // get href value and parse URL
            const href = targetNode.getAttribute('href');
            let url;
            try {
                url = new URL(href, 'https://' + window.location.host);
            } catch (error) {
                this.logger.error('Failed parsing URL for navigation', { href, error });
                return;
            }

            if (debugLog) this.logger.log('Handling parsed click url', url);

            if (this.isSameOrigin(url) && url.pathname.startsWith('/deeplink/')) {
                // we found an app deeplink!
                if (window.nativeJsBridge.isWebview) {
                    // In webviews, we call the JS-Bridge
                    try {
                        const bridgeData = { url: url.href };
                        if (debugLog) this.logger.log('Using JS-Bridge for deeplink', { bridgeData });
                        window.nativeJsBridge.callHandler('deeplink', bridgeData);
                        e.preventDefault();
                    } catch (error) {
                        this.logger.error('Failed calling webview js bridge for deeplink', error);
                    }
                    return;
                } else {
                    // let native handle the link
                    if (debugLog) this.logger.log('App deeplink detected, loading via browser page view');
                    return;
                }
            }

            if (targetNode.target === '_blank' || targetNode.target === '_top') {
                if (debugLog) this.logger.log('Ignoring link because of target="_blank/_top"');
                return;
            }

            // Open a new tab when a user presses CTRL on Windows/Linux or COMMAND on macOS
            if (e.ctrlKey || e.metaKey) {
                if (debugLog) {
                    this.logger.log('Opening link in new tab because CTRL or CMD key is pressed', {
                        CTRL: e.ctrlKey,
                        CMD: e.metaKey,
                        e,
                    });
                }

                return;
            }

            // From this point we expect an internal or external link
            // So it's safe to start the progressbar (also here for external links)
            this.startProgressBar();

            if (targetNode.dataset.navhandlerlink === 'ignore') {
                if (debugLog) this.logger.log('Ignoring link because of data-navhandlerlink="ignore"');
                return;
            }

            if (!this.isSameOrigin(url)) {
                return;
            }

            // View Transitions - pick card/cover main media
            const card = targetNode.closest('.c-card, .c-cover');
            if (card) {
                const cardMedia = card.querySelector('.c-card__media .c-image, .c-card__media .c-cover, picture');
                if (cardMedia) {
                    // unset any other main-image
                    document.querySelectorAll('[style*="view-transition-name"]').forEach((element) => {
                        if (element.style.viewTransitionName === 'main-image') {
                            this.logger.log('Removing previous main-image view-transition-name', element);
                            element.style.viewTransitionName = 'none';
                        }
                    });
                    cardMedia.style.viewTransitionName = 'main-image';
                }
            }

            // Handle scroll target
            let scrollToSelector = null;
            if (url.hash !== '') {
                const id = url.hash.substring(1); // replace the '#'
                scrollToSelector = `[id="${id}"]`;
                if (debugLog) this.logger.log('Detected scroll to selector', { scrollToSelector });
            }

            if (debugLog) this.logger.log('Loading link via navigation handler', url);

            e.preventDefault();
            this.navigateTo(targetNode.href, 'GET', null, scrollToSelector);
            return false;
        }, {
            capture: false,
            once: false,
        });

        window.addEventListener('submit', (e) => {
            this.logger.log('submit triggered', e);
            const targetUrl = e.target.action || false;
            if (!targetUrl || targetUrl === '') {
                return;
            }

            let url;
            try {
                url = new URL(targetUrl, 'https://' + window.location.host);
            } catch (error) {
                this.logger.error('Failed parsing URL for form submit', { targetUrl, error });
                return;
            }

            if (!this.isSameOrigin(url)) {
                return;
            }

            e.preventDefault();
            const formData = new FormData(e.target);

            // FormData does not know the form was submitted, so it does not get the value of the submit button
            // see https://stackoverflow.com/a/48322934
            let submitButton;
            if (e.submitter && e.submitter.name) {
                // e.submitter may include the HTMLElement - see https://stackoverflow.com/a/65461095
                // this.logger.warn('submitter found', e.submitter);
                submitButton = e.submitter;
            } else {
                submitButton = e.target.querySelector('button[type="submit"]');
            }

            if (submitButton && submitButton.name) {
                formData.append(submitButton.name, submitButton.value);
            }

            let scrollToSelector = e.target.id || (e.target.dataset.scrollto || null);
            if (scrollToSelector) {
                scrollToSelector = `[id="${scrollToSelector}"]`;
            }

            this.navigateTo(url.href, e.target.method || 'POST', formData, scrollToSelector);
            return false;
        });
    }

    isSameOrigin (url) {
        // check if its same origin
        if (window.location.host.startsWith('login.')) {
            // This is the oauth
            return false;
        }
        if (url.protocol !== 'https:') {
            this.logger.log('Ignoring link because its not relative (starting with `/`) and not with `https://`');
            return false;
        }

        if (url.host !== window.location.host && url.host !== window.location.host.replace(/^(www\.)/, '')) {
            this.logger.log('Ignoring link because its not same-site', {
                link: url.host,
                current_host: window.location.host,
            });
            return false;
        }

        return true;
    }

    startProgressBar () {
        // this.logger.log('starting progressbar');
        nprogress.start();
        document.documentElement.classList.add('navhandler-is-loading');
    }

    /**
     *
     * @param {float} progress value from 0 to 1
     */
    updateProgressbar (progress) {
        nprogress.set(progress);
    }

    stopProgressBar () {
        // this.logger.log('stopping progressbar');
        nprogress.done();
        document.documentElement.classList.remove('navhandler-is-loading');
    }

    abortPreviousNavigation () {
        this.abortController.abort();
        this.abortController = new AbortController();
    }

    async navigateTo (url, method = 'GET', formData = null, scrollElementSelector = null, isPopstateEvent = false) {
        // this.logger.log('Should navigate', { url, method, formData });
        this.abortPreviousNavigation();

        // get requests don't allow formData in body -> rewrite to query string
        if (method.toUpperCase() === 'GET' && formData !== null) {
            const urlObj = new URL(url);
            (new URLSearchParams(formData)).forEach((value, key) => {
                urlObj.searchParams.append(key, value);
            });
            url = urlObj.toString();
            formData = null;
        }

        this.triggerEvent('before-fetch', {
            url,
            method,
        });
        this.startTime = Date.now();
        this.startProgressBar();

        if (!scrollElementSelector) {
            // this.scrollToTop();
        }

        try {
            const { response, url: responseUrl } = await this.fetchPage(url, method, formData);

            const responseUrlObj = new URL(responseUrl);
            const responseUrlParams = new URLSearchParams(responseUrlObj.search);
            if (responseUrlParams.has('logout', '1')) {
                this.triggerFullPageReload(responseUrl, 'LOGOUT_RELOAD');
            }

            const html = await response.text();

            if (response.ok || html) {
                const newDocument = this.parser.parseFromString(html, 'text/html');

                if (response.ok) {
                    this.logger.log('Response was ok', { response });

                    this.triggerEvent('before-render', {
                        url,
                        method,
                    });

                    await this.renderNewDocument(responseUrl, newDocument);

                    if (scrollElementSelector) {
                        this.scrollToElement(scrollElementSelector);
                    } else {
                        // make sure scrolling was far enough
                        if (window.scrollY > 210) {
                            this.logger.log('scrollY too high, scrolling to top again', { scrollY: window.scrollY });
                            setTimeout(() => this.scrollToTop(), 1);
                        }
                    }

                    const took = (Date.now() - this.startTime) + 'ms';
                    // this.logger.log('page rendered', { took });

                    if (isPopstateEvent === false) {
                        this.pushState(responseUrl, method, scrollElementSelector);
                    }

                    this.triggerEvent('render', {
                        took,
                        started: this.startTime,
                        responseUrl,
                        newDocument,
                    });
                } else {
                    this.logger.error('Fetched resource was not ok.', response);
                    if (newDocument.querySelector('[data-antenneerrorcontent]')) {
                        this.eventEmitter.emit('dialog.renderAndShow', {
                            title: 'Oops…',
                            description: 'Es trat ein Fehler auf',
                            content: newDocument.querySelector('[data-antenneerrorcontent]').innerHTML,
                            cssContentClasses: 'c-dialog__content--whitebg c-dialog__content--errordialog',
                        });
                        throw new NavigationError('Received Error Page');
                    } else {
                        throw new Error('Unexpected Response');
                    }
                }
            } else {
                throw new Error('Unexpected Response');
            }

            this.stopProgressBar();
        } catch (error) {
            if (error.name === 'AbortError') {
                this.logger.warn('Navigation was aborted', { url });
                return;
            }
            this.stopProgressBar();
            this.logger.error('Failed to navigate', { error_name: error.name, error_message: error.message, url });

            if (error.name !== 'NavigationError') {
                if (window.console) console.error(error);
                this.eventEmitter.emit('dialog.renderAndShow', {
                    title: 'Oops…',
                    description: 'Es trat ein Fehler auf',
                    content: `
                        <h1>Oops…</h1>
                        <p>Das Laden der Seite ist fehlgeschlagen. Bitte versuche die Seite neuzuladen:</p>
                        <button onclick="window.location.reload()" class="c-button u-no-margin">Seite neuladen</button>
                    `,
                    cssContentClasses: 'c-dialog__content--whitebg c-dialog__content--errordialog',
                });
            }
        }
    }

    async fetchPage (url, method = 'GET', formData = null) {
        const self = this;
        const options = await this.createRequestOptions(method, formData);
        const response = await fetch(url, options);

        if (!response.body) {
            throw Error('ReadableStream not yet supported in this browser.');
        }

        const contentEncoding = response.headers.get('content-encoding');
        let contentLength = response.headers.get(contentEncoding ? 'x-response-size' : 'content-length');
        if (contentLength === null) {
            this.logger.log('Response did not contain a `Content-Length` or `X-Response-Size` header.');
            contentLength = 100000; // set a "default" value for calculation later
        }

        const total = parseInt(contentLength, 10);
        let loaded = 0;

        return {
            response: new Response(
                new ReadableStream({
                    start (controller) {
                        const reader = response.body.getReader();
                        read();
                        function read () {
                            reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                loaded += value.byteLength;

                                self.updateProgressbar(loaded / total);
                                controller.enqueue(value);
                                read();
                            }).catch(error => {
                                console.error(error);
                                controller.error(error);
                            });
                        }
                    },
                }),
                {
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                },
            ),
            url: response.url,
        };
    }

    /**
     * @param {String} method
     * @param {FormData} formData
     *
     * @returns {Promise<RequestInit>}
     */
    async createRequestOptions (method, formData) {
        const requestOptions = {
            method,
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            body: formData,
            headers: {
                'X-ThisIsFromNaviationHandler': 'true',
            },
            redirect: 'follow',
            signal: this.abortController.signal,
        };

        /**
         * We decided with Nuuk to append the Antenne Auth-Token to all JavaScript
         * requests, because the native apps are not allowed to modify webview
         * request headers. We’re adding the JWT to authenticate the request.
         */
        if (window.nativeJsBridge.isWebview) {
            try {
                const authToken = await window.nativeJsBridge.callHandler('antenne.auth.token', {});

                if (typeof authToken !== 'string') {
                    throw new TypeError('Received ABY-Auth-Token is not a string value');
                }

                if (authToken === '') {
                    throw new TypeError('Received ABY-Auth-Token is an empty string value');
                }

                if (authToken.split('.').length !== 3) {
                    throw new TypeError('Received ABY-Auth-Token is an invalid format (expecting signed JWT)');
                }

                this.logger.log('Adding Authorization header to API request', authToken);
                requestOptions.headers.Authorization = `Bearer ${authToken}`;
            } catch (error) {
                this.logger.error(
                    'Failed to get ABY-Auth-Token from JS-Bridge. Proceeding request without it.',
                    JSON.stringify(error),
                );
            }
        }

        return requestOptions;
    }

    async renderNewDocument (url, newDocument) {
        const currentHash = this.gatherAssetsHash(document);
        const newHash = this.gatherAssetsHash(newDocument);
        if (currentHash !== newHash) {
            this.logger.warn('Assets changed. Triggered Reload.');
            this.triggerFullPageReload(url, 'ASSETS_CHANGED');
        }

        // tabNav handling (sets view-transition-name if tabNav contents have changed, e.i. switiching parents)
        const oldTabNav = document.querySelector('.c-tabnav');
        if (oldTabNav) {
            oldTabNav.style.viewTransitionName = 'none';
        }
        const newTabNav = newDocument.querySelector('.c-tabnav');
        if (oldTabNav && newTabNav) {
            if (oldTabNav.textContent.replace(/\s/g, '') !== newTabNav.textContent.replace(/\s/g, '')) {
                oldTabNav.style.viewTransitionName = 'tabnav';
                newTabNav.style.viewTransitionName = 'tabnav';
            }
        }

        // Find main image of new document and view-transition-name
        const mainImage = this.findMainImage(newDocument);
        if (mainImage) {
            mainImage.style.viewTransitionName = 'main-image';
        }

        // Starting the Transition
        if (!document.startViewTransition) {
            // View-Transitions not supported
            this.updateTheDOM(url, newDocument);
        } else {
            try {
                await document.startViewTransition(() => {
                    this.updateTheDOM(url, newDocument);
                }).ready;
            } catch (error) {
                if (error.name === 'InvalidStateError') {
                    // may happen with CSS-View-Transitions and multiple elements with same view-transition-name
                    this.logger.error(error);
                } else {
                    throw error;
                }
            }
        }
    }

    findMainImage (document) {
        return document.querySelector(`
            .l-page-content[data-tpl="3"] .c-cover,
            .l-page-content[data-tpl="23"] .c-cover,
            .l-page-content[data-tpl="6"] .l-grid__cell--medium-12 .c-image--content,
            .l-page-content[data-tpl="15"] .l-grid__cell--medium-12 .c-image--content,
            .l-page-content[data-tpl="27"] .l-grid__cell--medium-12 .c-image--content,
            .l-page-content[data-tpl="26"] .l-grid__cell--medium-12 .c-image--content
        `);
    }

    updateTheDOM (url, newDocument) {
        // Replace page-content
        this.replaceOldNodesWithNewOnes(url, newDocument);

        // To make browsers eval the script, it needs to be a new createElement()
        const scriptNodes = document.querySelectorAll(
            `#main script:not([data-${this.sectionAttributeName}]):not([type="application/json"])`,
        );
        scriptNodes.forEach(node => {
            this.insertScriptNode(node, node);
        });
    }

    gatherAssetsHash (thisDocument) {
        const assets = thisDocument.querySelectorAll('[data-navhandlerasset="reload"]');
        let contents = '';
        assets.forEach(asset => {
            contents += asset.outerHTML;
        });
        return contents;
    }

    insertScriptNode (existingNode, newNode) {
        // To make browsers eval the script, it needs to be a new createElement()
        const script = document.createElement('script');
        script.innerHTML = '(() => {' + newNode.innerHTML + '})();';
        // Copy over all attributes
        Array.from(newNode.attributes).forEach((attribute, index) => {
            script.setAttribute(attribute.name, attribute.value || '');
        });
        // insert at same place
        existingNode.parentNode.insertBefore(script, existingNode.nextSibling);
        existingNode.parentNode.removeChild(existingNode);
    }

    replaceOldNodesWithNewOnes (url, newDocument) {
        const contentAttribute = `[data-${this.sectionAttributeName}="content"]`;
        if (!newDocument.querySelector(contentAttribute)) {
            this.logger.warn(
                'New document does not have a `' + contentAttribute + '` attribute. Replacing the full page.',
            );
            document.documentElement.replaceWith(newDocument.documentElement);
            return;
        }

        if (!document.querySelector(contentAttribute)) {
            this.logger.warn(
                'Current document does not have a `' + contentAttribute + '` attribute. Reloading.',
            );
            this.logger.error('Current page does not contain `' + contentAttribute + '`. Triggered Reload.');
            this.triggerFullPageReload(url, 'MISSING_CONTENT_AREA');
        }

        document.querySelectorAll(`[data-${this.sectionAttributeName}]`).forEach(node => {
            const id = node.dataset[this.sectionAttributeName];
            const newNode = newDocument.querySelector(`[data-${this.sectionAttributeName}="${id}"]`);

            if (!newNode) {
                this.logger.log(`Node [data-${this.sectionAttributeName}="${id}"] not found in new page`);
                return;
            }

            if (node.tagName.toLowerCase() === 'script') {
                this.insertScriptNode(node, newNode);
            } else {
                node.replaceWith(newNode);
            }
        });

        // Replace existing meta-tags with new ones
        document.querySelectorAll('meta[name], meta[property], link[rel="canonical"]').forEach(node => {
            const id = node.getAttribute('name') || node.getAttribute('property') || node.getAttribute('rel');
            const newNode = newDocument.querySelector(`meta[name="${id}"], meta[property="${id}"], link[rel="${id}"]`);
            if (!newNode) {
                // remove old meta-tags that do not exist in new document
                node.remove();
                return;
            }

            node.replaceWith(newNode);
        });
        // Append meta-tags, not existing in old document
        newDocument.querySelectorAll('meta[name], meta[property], link[rel="canonical"').forEach(newNode => {
            const id = newNode.getAttribute('name') || newNode.getAttribute('property') || newNode.getAttribute('rel');
            const oldNode = document.querySelector(`meta[name="${id}"], meta[property="${id}"], link[rel="${id}"]`);
            if (!oldNode) {
                document.head.append(newNode);
            }
        });

        // update page-wrapper classes (has-hero)
        const oldPageWrapper = document.querySelector('.l-page-wrapper');
        const newPageWrapper = newDocument.querySelector('.l-page-wrapper');
        if (oldPageWrapper && newPageWrapper) {
            oldPageWrapper.className = newPageWrapper.className;
        } else {
            this.logger.log('failed to update page-wrapper classes', { oldPageWrapper, newPageWrapper });
        }

        // Update document title
        document.title = newDocument.title;
    }

    sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    scrollToTop () {
        window.scrollTo(0, 0);
    }

    /**
     * @param {HTMLElement} elementSelector
     */
    scrollToElement (elementSelector) {
        const element = document.querySelector(elementSelector);
        if (!element) {
            this.logger.warn('Element for scroll position not found');
            this.scrollToTop();
            return;
        }

        const currentScrollPosition = window.scrollY;
        const viewportHeight = window.innerHeight;

        const elementPositionFromTopOfViewport = element.getBoundingClientRect().top;

        const shouldScroll = !(
            elementPositionFromTopOfViewport > 0 &&
            elementPositionFromTopOfViewport < viewportHeight / 2
        );

        if (!shouldScroll) {
            // If element is in first 50% of current viewport, we do not scroll
            return;
        }

        let elementsPixelFromTopOfPage = elementPositionFromTopOfViewport + currentScrollPosition;
        if (elementsPixelFromTopOfPage > 16) {
            elementsPixelFromTopOfPage -= 16;
        }

        window.scrollTo(0, elementsPixelFromTopOfPage);
    }

    // just fetch the page again
    refreshCurrentPage (scrollElementSelector = null) {
        this.navigateTo(window.location.href, 'GET', null, scrollElementSelector);
    }

    // Do a full browser reload of the complete page
    triggerFullPageReload (url, reason) {
        this.logger.warn('Triggered a page reload', { reason });
        this.triggerEvent('reload', {
            url,
            reason,
        });
        window.location = url;
        throw new NavigationError(reason);
    }

    /**
     * @param {NavigationEvent} event
     * @param {((data: any) => any)} handler
     *
     * @returns {this}
     */
    on (event, handler) {
        if (typeof event !== 'string') {
            throw new TypeError('"event" must be a string.');
        }
        if (typeof handler !== 'function') {
            throw new TypeError('"handler" must be a function."');
        }

        if (event === 'ready' && this.readyAlreadyFired === true) {
            // run handler immediately if ready was already fired before
            // mimiks behavior of commonMethods.isDomReady()
            handler();
        }

        this._eventHandlers[event] = this._eventHandlers[event] || [];
        this._eventHandlers[event].push(handler);

        return this;
    }

    triggerEvent (event, data = {}) {
        if (typeof event !== 'string') {
            throw new TypeError('"event" must be a string.');
        }
        if (typeof data !== 'object') {
            throw new TypeError('"data" must be an object."');
        }

        if (event === 'ready') {
            this.readyAlreadyFired = true;
        }

        const eventData = {
            type: event,
            detail: data,
        };

        window.dispatchEvent(new CustomEvent('navigationhandler:' + event, eventData));

        const eventHandlers = this._eventHandlers[event] || [];
        const catchAllHandlers = this._eventHandlers['*'] || [];
        [...catchAllHandlers, ...eventHandlers].forEach(handler => {
            try {
                handler(eventData);
            } catch (error) {
                this.logger.error(`An ${event} handler had an error`, error);
            }
        });
        return this;
    }
}

export default NavigationHandler;
