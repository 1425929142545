/**
 * Event Emitter (with queue)
 * This implements the mitt emitter extended with a queue
 * The queue persists events before an handler is added to listen
 *
 * highly inspired by https://github.com/developit/mitt/issues/161#issuecomment-1129020060
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';
import mitt from 'mitt';

class Emitter {
    getClassName () { return 'Emitter'; }
    constructor () {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.emitter = mitt();

        this.queue = new Map();
        this.emitter.on('*', (type, event) => {
            // For debugging:
            this.logger.log(`'${type}' emitted`, { data: event });

            // Disable processing when event is already registered
            if (this.emitter.all.has(type)) return;

            if (!this.queue.has(type)) {
                this.queue.set(type, []);
            }
            this.queue.get(type).push(event);
        });

        this.logger.log('Emitter and queue initialized');
    }

    on (type, handler) {
        this.emitter.on(type, handler);
        // Original (triggering 'emit' again)
        // (this.queue.get(type) || []).forEach(v => this.emitter.emit(type, v));
        // alternative by CS:
        (this.queue.get(type) || []).forEach(v => handler(type, v));

        // Free the resources
        this.queue.delete(type);
    }

    off (type, handler) {
        this.emitter.off(type, handler);
    }

    emit (type, event = {}) {
        this.emitter.emit(type, event);
    }
}

export default Emitter;
