/**
 * Dialog Class
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 *
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('mitt').Emitter} EventEmitter
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 * @typedef {import('./Slider').default} Slider
 */

'use strict';

import ClassLogger from 'ClassLogger';
import A11yDialog from 'a11y-dialog';

class Dialog {
    getClassName () { return 'Dialog'; }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     * @param {EventEmitter} eventEmitter
     * @param {Slider} sliderService
     */
    constructor (commonMethods, navigationHandler, eventEmitter, sliderService) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;
        this.eventEmitter = eventEmitter;
        this.sliderService = sliderService;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());

        this.eventEmitter.on('dialog.renderAndShow', (placeholders) => {
            const id = 'dialog-' + (Math.random() + 1).toString(36).substring(7);
            const dialogNode = this.commonMethods.markupToElement(
                window.antenne.templates.dialog(
                    id,
                    placeholders.title,
                    placeholders.description,
                    placeholders.content,
                    placeholders.cssContentClasses,
                    placeholders.classes,
                ),
            );
            document.body.append(dialogNode);
            const dialog = this.initDialogNode(dialogNode);
            dialog.show();
        });
    }

    init () {
        const dialogNodes = document.querySelectorAll('.c-dialog');

        if (dialogNodes.length === 0) {
            return;
        }

        dialogNodes.forEach(dialogNode => {
            this.initDialogNode(dialogNode);
        });
    }

    initDialogNode (dialogNode, closeable = true) {
        if (dialogNode.classList.contains('is-initialized')) {
            return;
        }

        // This must be done before initialized
        if (closeable === false) {
            dialogNode.setAttribute('role', 'alertdialog');
            const hideElement = dialogNode.querySelector('.c-dialog__overlay[data-a11y-dialog-hide]');
            if (hideElement) {
                hideElement.removeAttribute('data-a11y-dialog-hide');
            }
            const closeButton = dialogNode.querySelector('.c-dialog__close');
            if (closeButton) {
                closeButton.remove();
            }
        }

        const dialog = new A11yDialog(dialogNode);

        const lastFocus = document.activeElement;
        dialog.on('show', el => {
            el.setAttribute('aria-hidden', false);
            el.focus();

            this.eventEmitter.emit('dialog.show', dialog);
        }).on('hide', function (el) {
            el.setAttribute('aria-hidden', true);
            if (lastFocus) {
                lastFocus.focus();
            } else {
                el.blur();
            }
        });

        dialogNode.classList.add('is-initialized');
        this.logger.log('Initialized dialog', { dialogNode });

        this.sliderService.handleSlidersInWrapper(dialogNode);

        // This custom event is used in other services, to trigger a dialog (e.g. regwall)
        const eventHandler = () => {
            this.logger.log('Received event to show dialog ' + dialogNode.id);
            dialog.show();
        };

        this.eventEmitter.on('dialog.show.' + dialogNode.id, eventHandler);
        // We must remove the handler before we render another page
        document.documentElement.addEventListener('navigationhandler:render', (e) => {
            this.eventEmitter.off('dialog.show.' + dialogNode.id, eventHandler);
        }, { once: true });

        if (dialogNode.dataset.autoopen === 'true') {
            this.logger.log('Automatically opening modal', { dialogNode });
            dialog.show();
        }

        return dialog;
    }
}

export default Dialog;
