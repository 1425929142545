class AbortablePromise extends Promise {
    /**
     * @param {AbortablePromiseExecutor} executor
     */
    constructor (executor) {
        const abortController = new AbortController();

        super((resolve, reject) => {
            executor(resolve, reject, abortController);
        });

        this.abort = (reason) => abortController.abort(reason);
    }
}

export default AbortablePromise;
